@import 'config';


// ==========================================================================
// Utilities
// ==========================================================================
// Import if Google Fonts URL is defined
@if variable-exists(font-url--google) {
    @import url($font-url--google);
}

// Calculate em values
@function em($target, $context: $base__font-size) {
    @return ($target / $context) * 1em;
}

// Call the color palette modifiers
@function palette($palette, $shade: 'base') {
    @return map-get(map-get($palettes, $palette), $shade)
}

// Mixins

/* ================= Image Replace 

======================================*/

@mixin img-replace($img, $w, $h, $disp: block) {
    background-image: url('#{$path--rel}/#($img)');
    background-repeat: no-repeat;
    width: $w;
    height: $h;
    display: $display;
}


/* ================= Pseudo Element 

======================================*/

@mixin p-el($el,
$el-w: null,
$el-h: null) {
    @if $el=="before" or $el=="after" {
        &:#{$el} {
            @extend %pseudos;
            width: $el-w;
            height: $el-h;
            @content;
        }
    }
    else {
        @warn "'#{$el}' is not a valid pseudo-element.";
    }
}


/* ================= Media Query mixin
USE MEDIA QUERY AS FOLLOWS --
    @include respond-to(tiny) {   
        MEDIA SPECIFIC RULES HERE   
    }
======================================*/

$breakpoints: ( 'tiny': ( max-width: 320px),
'x-small': ( max-width: 480px),
'small': ( max-width: 606px),
'medium': ( max-width: 980px),
'large': ( max-width: 1024px),
'x-large': ( max-width: 1170px));
@mixin respond-to($name) {
    // If the key exists in the map
    @if map-has-key($breakpoints,
    $name) {
        // Prints a media query based on the value
        @media #{inspect(map-get($breakpoints, $name))} {
            @content;
        }
    } // If the key doesn't exist in the map
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoints}`. "+"Please make sure it is defined in `$breakpoints` map.";
    }
}

// breakpoints specific
// usage: @include breakpoint(940){ width:100%; }
@mixin breakpoint($size) {
    @media only screen and (max-width: $size + px) {
        @content;
    }
}


// Breakpoints
//
// Markup: _breakpoints.hbs
//
// Styleguide 1.5
//

/*
    This file houses our breakpoints and their
    mixins. You will find all of our breakpoint
    values here, as well as a few mixins for
    creating responsive styles.

    These are:

    @include breakpoint-up()
        This will apply styles to an element
        with a min-width value.

    @include breakpoint-down()
        This will apply styles to an element
        with a max-width value.

    @include breakpoint-between()
        This will apply styles to an element
        between two sizes - 
        min-width then max-width.

*/

// Used for our default mixins
$breakpoints: ( xs: 320px, sm: 640px, md: 768px, lg: 1024px, xl: 1200px ) !default;

/*
    Used for our breakpoint-down mixin

        These have a one pixel difference
        so as there is no overlap.
*/
$breakpoints-max: ( xs: 319px, sm: 639px, md: 767px, lg: 1023px, xl: 1199px ) !default;


// Min Width
//
// @include breakpoint-up(sm)
// ------------------------------------------------
@mixin breakpoint-up($breakpoint) {

  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }
}


// Max Width
//
// @include breakpoint-down(sm)
// ------------------------------------------------
@mixin breakpoint-down($breakpoint) {

  @if map-has-key($breakpoints-max, $breakpoint) {
    @media (max-width: map-get($breakpoints-max, $breakpoint)) {
      @content;
    }
  }
}


// Between Widths
//
// @include breakpoint-between(sm, md)
// ------------------------------------------------
@mixin breakpoint-between($breakpoint, $breakpoint2) {

  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: map-get($breakpoints, $breakpoint)) and (max-width: map-get($breakpoints, $breakpoint2)) {
      @content;
    }
  }
}


// breakpoints specific
// Beautiful designs need specifics
// usage: @include breakpoint(940){ width:100%;}
// ------------------------------------------------
@mixin breakpoint($size) {
  @media only screen and (max-width : $size + px) {
    @content;
  }
}
//Form input box styles
@mixin border-radius($radius) {
    -moz-border-radius: $radius;
    border-radius: $radius;
}

//Form Placeholder Text styles
@mixin placeholder-txt ($color,
$font-size,
$line-height: 16px) {
    &::-webkit-input-placeholder {
        color: $color;
        font-size: $font-size;
        line-height: $line-height;
    }
    &:-moz-placeholder {
        /* Firefox 18- */
        line-height: $line-height;
        color: $color;
        font-size: $font-size;
    }
    &::-moz-placeholder {
        /* Firefox 19+ */
        line-height: $line-height;
        color: $color;
        font-size: $font-size;
    }
    &:-ms-input-placeholder {
        color: $color;
        font-size: $font-size;
        line-height: $line-height;
    }
}

//Transition Mixin
@mixin transition($property: all,
$duration: 0.2s,
$timing: ease) {
    -webkit-transition: $property $duration $timing;
    -moz-transition: $property $duration $timing;
    -o-transition: $property $duration $timing;
    transition: $property $duration $timing;
}

//easy gradient mixin
@mixin lgradient($deg,
$colorbottom,
$colortop) {
    background: -moz-linear-gradient($deg + deg, $colorbottom, $colortop);
    background: -webkit-linear-gradient($deg + deg, $colorbottom, $colortop);
    background: linear-gradient($deg + deg, $colorbottom, $colortop);
}

//Box Shadow Mixin with defaults
@mixin box-shadow($left: 0,
$top: 0,
$blur: 10px,
$color: rgba(palette(grey), 0.3),
$inset: "") {
    -webkit-box-shadow: $left $top $blur $color #{$inset};
    -moz-box-shadow: $left $top $blur $color #{$inset};
    box-shadow: $left $top $blur $color #{$inset};
}

// To create arrows
@mixin arrow($position) {
    content: '';
    background-color: $mango-tango;
    border-radius: 30px;
    height: 12px;
    left: 50%;
    position: absolute;
    top: 20px;
    transform: translateX(-50%) translateY(-50%);
    transition: .185s ease;
    width: 4px;
    @if $position=="left" {
        left: calc(50% - 7px);
        transform: rotate(135deg);
    }
    @if $position=="right" {
        transform: rotate(-135deg);
    }
}

[class*="bg-"] {
    padding: 65px 0;
}

[class*="bg-"] .colouredcontainerblock--content *:not(.btn) {
    color: #fff;
} // We don't want white text on a white bg
.bg-offwhite,
.bg-white {
    background-color: #FFFFFF !important;
    .colouredcontainerblock--content *:not(.btn) {
   color: $color-deep;
    }
} // Background color variants
.bg {
    &-black {
        background-color: #2B2B2B !important;
    }
    &-blue {
        background: linear-gradient(to bottom, #3B6597, #062565) !important;
    }
    &-orange {
        background-color: $mango-tango !important;
    }
    &-offwhite {
        background-color: #E7E8EA !important;
    }
}

// Margin & Padding For Loops
// ------------------------------------------------

@for $i from 0 through 10 {
  $j: (100/10 * $i);

  .mt-#{$i} {
    margin-top: unquote($j + 'px') !important;
  }
}

@for $i from 0 through 10 {
  $j: (100/10 * $i);

  .mb-#{$i} {
    margin-bottom: unquote($j + 'px') !important;
  }
}

@for $i from 0 through 10 {
  $j: (100/10 * $i);

  .ml-#{$i} {
    margin-left: unquote($j + 'px') !important;
  }
}

@for $i from 0 through 10 {
  $j: (100/10 * $i);

  .mr-#{$i} {
    margin-right: unquote($j + 'px') !important;
  }
}


@for $i from 0 through 10 {
  $j: (100/10 * $i);

  .pt-#{$i} {
    padding-top: unquote($j + 'px') !important;
  }
}

@for $i from 0 through 10 {
  $j: (100/10 * $i);

  .pb-#{$i} {
    padding-bottom: unquote($j + 'px') !important;
  }
}

@for $i from 0 through 10 {
  $j: (100/10 * $i);

  .pl-#{$i} {
    padding-left: unquote($j + 'px') !important;
  }
}

@for $i from 0 through 10 {
  $j: (100/10 * $i);

  .pr-#{$i} {
    padding-right: unquote($j + 'px') !important;
  }
}

@for $i from 0 through 10 {
  $j: (100/10 * $i);

  .p-#{$i} {
    padding: unquote($j + 'px') !important;
  }
}
