// Icons
%padding {
    h2 {
        padding-top: 1px;
        line-height: 43px;
        &:before {
            width: 40px;
            height: 40px;
            position: relative;
            top: 6px;
            margin-right: 15px;
        }
    }
    
    h3 {
        line-height: 40px;
        padding-top: 2px;
        &:before {
            width: 36px;
            height: 36px;
            position: relative;
            top: 6px;
            margin-right: 14px;
        }
    }
    
    h4 {
        padding-top: 2px;
        line-height: 36px;
        &:before {
            width: 34px;
            height: 34px;
            position: relative;
            top: 6px;
            margin-right: 13px;
        }
    }
    
    h5 {
        padding-top: 2px;
        line-height: 35px;
        &:before {
            width: 32px;
            height: 32px;
            position: relative;
            top: 6px;
            margin-right: 13px;
        }
    }
    
    h6 {
        padding-top: 2px;
        line-height: 34px;
        &:before {
            width: 30px;
            height: 30px;
            position: relative;
            top: 6px;
            margin-right: 12px;
        }
    }
}

h2[class*=ico-] {
    padding-top: 1px;
    line-height: 43px;
    &:before {
        width: 40px;
        height: 40px;
        position: relative;
        top: 6px;
        margin-right: 15px;
    }
}

h3[class*=ico-] {
    line-height: 40px;
    padding-top: 2px;
    &:before {
        width: 36px;
        height: 36px;
        position: relative;
        top: 6px;
        margin-right: 14px;
    }
}

h4[class*=ico-] {
    padding-top: 2px;
    line-height: 36px;
    &:before {
        width: 34px;
        height: 34px;
        position: relative;
        top: 6px;
        margin-right: 13px;
    }
}

h5[class*=ico-] {
    padding-top: 2px;
    line-height: 35px;
    &:before {
        width: 32px;
        height: 32px;
        position: relative;
        top: 6px;
        margin-right: 13px;
    }
}

h6[class*=ico-] {
    padding-top: 2px;
    line-height: 34px;
    &:before {
        width: 30px;
        height: 30px;
        position: relative;
        top: 6px;
        margin-right: 12px;
    }
}
//$map-name: ( 'icon-name': 'file-name',
$ico-images: ( 
    'account': 'icon-account',
    'address': 'icon-address',
    'alert': 'icon-alert',
    'arrows': 'icon-arrows',
    'atm': 'icon-atm',
    'attachment': 'icon-attachment',
    'banknote-strike': 'icon-banknote-strike',
    'branch': 'icon-branch',
    'broken-piggy-bank': 'icon-broken-piggy-bank',
    'builder-hardhat': 'icon-builder-hardhat',
    'bullet-list': 'icon-bullet-list',
    'business': 'icon-business',
    'calculator': 'icon-calculator',
    'calendar': 'icon-calendar',
    'car': 'icon-car',
    'change': 'icon-change',
    'clock': 'icon-clock',
    'cloud': 'icon-cloud',
    'creditcard': 'icon-creditcard',
    'deposit-accounts': 'icon-deposit-accounts',
    'desktop': 'icon-desktop',
    'doc': 'icon-doc',
    'documents': 'icon-documents',
    'education': 'icon-education',
    'euro': 'icon-euro',
    'fees': 'icon-fees',
    'flexible': 'icon-flexible',
    'folder': 'icon-folder',
    'future': 'icon-future',
    'gift': 'icon-gift',
    'hammer': 'icon-hammer',
    'hands-shaking': 'icon-hands-shaking',
    'help': 'icon-help',
    'help-bubble': 'icon-help-bubble',
    'hourglass': 'icon-hourglass',
    'house': 'icon-house',
    'house-extension': 'icon-house-extension',
    'important-info': 'icon-important-info',
    'insurance': 'icon-insurance',
    'interest-rates': 'icon-interest-rates',
    'investments': 'icon-investments',
    'list': 'icon-list',
    'loans': 'icon-loans',
    'lock': 'icon-lock',
    'map': 'icon-map',
    'mobile': 'icon-mobile',
    'money': 'icon-money',
    'newspaper': 'icon-newspaper',
    'num0-9': 'icon-num0-9',
    'open-24hr': 'icon-open-24hr',
    'padlock-euro': 'icon-padlock-euro',
    'pdf': 'icon-pdf',
    'pencil': 'icon-pencil',
    'phone': 'icon-phone',
    'piggy': 'icon-piggy',
    'pocket-money': 'icon-pocket-money',
    'print': 'icon-print',
    'rainy-day': 'icon-rainy-day',
    'random': 'icon-random',
    'search': 'icon-search',
    'selfbuild-mortgage': 'icon-selfbuild-mortgage',
    'send': 'icon-send',
    'settings': 'icon-settings',
    'shopping-cart': 'icon-shopping-cart',
    'signposts': 'icon-signposts',
    'sitemap': 'icon-sitemap',
    'sort': 'icon-sort',
    'speech-bubble': 'icon-speech-bubble',
    'student': 'icon-student',
    'suitcase': 'icon-suitcase',
    'sun': 'icon-sun',
    'switch-account': 'icon-switch-account',
    'switch-card': 'icon-switch-card',
    'switch-house': 'icon-switch-house',
    'switch-mortgage': 'icon-switch-mortgage',
    'tablet': 'icon-tablet',
    'tips': 'icon-tips',
    'top-tips': 'icon-top-tips',
    'top-tip-2': 'icon-top-tip-2',
    'tree': 'icon-tree',
    'twitter': 'icon-twitter',
    'twm': 'icon-twm',
    'umbrella': 'icon-umbrella',
    'unlock': 'icon-unlock',
    'wallet': 'icon-wallet',
    'warning': 'icon-warning',
    'waystobank': 'icon-waystobank',
    'wedding': 'icon-wedding',
    'wrench': 'icon-wrench',
    'youtube': 'icon-youtube',
    'no0': 'icon-no0',
    'no01': 'icon-no01',
    'no02': 'icon-no02',
    'no03': 'icon-no03',
    'no04': 'icon-no04',
    'no05': 'icon-no05',
    'no06': 'icon-no06',
    'no07': 'icon-no07',
    'no08': 'icon-no08',
    'no09': 'icon-no09',
    'alertexclamation': 'icon-alertexclamation',
    'investment-quiz': 'icon-investmentsquiz',
);

// Generate a class for each icon in each colour h2,h3,h4,h5,h6
@each $color in orange,lt-blue,dk-blue,deep-blue,black, white {
    @each $name,$icon in $ico-images {
        .ico-#{$name}--#{$color},
        .ico-#{$name}.ico-#{$color} {
            h2,h3,h4,h5,h6{
                &:before {
                  content: "";
                  background-image: url(/Content/img/icons/#{$icon}--#{$color}.svg);
                  background-size: 100% 100%;
                  position: relative;
                  display: inline-block;
                }
            }
            @extend %padding;
        }
        h2,h3,h4,h5,h6{
            &.ico-#{$name}--#{$color},
            &.ico-#{$name}.ico-#{$color} {
                &:before {
                    content: "";
                    background-image: url(/Content/img/icons/#{$icon}--#{$color}.svg);
                    background-size: 100% 100%;
                    position: relative;
                    display: inline-block; 
                }
            }
        }        
        .mobile-nav__menu {
            .ico-#{$name}--#{$color} {
                a::before {
                    content: "";
                    background-image: url(/Content/img/icons/#{$icon}--#{$color}.svg);
                    background-size: 100% 100%;
                    display: inline-block;
                }
            }
        }
    }
}

@each $name, $icon in $ico-images {
    .ico-#{$name} {
        h2, h3, h4, h5, h6 {
            &::before {
                content: "";
                background-image: url(/Content/img/icons/#{$icon}--orange.svg);
                background-size: 100% 100%;
                position: relative;
                display: inline-block;
            }
        }
        @extend %padding;
    }
    h2, h3, h4, h5, h6 {
        &.ico-#{$name} {
            &::before {
                content: "";
                background-image: url(/Content/img/icons/#{$icon}--orange.svg);
                background-size: 100% 100%;
                position: relative;
                display: inline-block;
            }
        }
    }
    .mobile-nav__menu {
        .ico-#{$name} {
            a::before {
                content: "";
                background-image: url(/Content/img/icons/#{$icon}--orange.svg);
                background-size: 100% 100%;
                display: inline-block;
            }
        }
    }
}



.mobile-nav__menu {
    .ico {
        a::before {
            padding-left: 0;
            background-size: 100% 100%;
            width: 30px;
            height: 30px;
            content: "";
            display: inline-block;
            position: absolute;
            display: inline-block;
            left: 5px;
            top: 10px;
        }
        li a::before {
            display: none;
        }
    }
}

.mega-dropdown {
    &-cols {
        .ico ul h6 {
            margin-left: -45px;
        }
    }
    .ico {
        background-size: 28px 28px;
        h6 {
            padding-top: 0;
            &:before {
                margin-right: 15px;
                top: 8px;
                @include respond-to(large) {
                    display: none;
                }
            }
            a {
                padding: 0;
                display: inline-block;
            }
        }
        > ul {
            padding-left: 45px;
        }
    }
}
