// ==========================================================================
// Global Config
// ==========================================================================

// Font Stacks

@import url(https://fonts.googleapis.com/css?family=Nunito:400,300,700,800);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:800);


$font-family--primary              :   'Nunito', Helvetica, Arial, sans-serif;
$font-family--secondary            :   'museo-sans-rounded', sans-serif;
$font-family--block                :   'Open Sans', sans-serif;




// Font Weights

$font-weight--thin          :   100;
$font-weight--light         :   300;
$font-weight--medium        :   500;
$font-weight--bold          :   700;
$font-weight--bolder        :   800;
$font-weight--ultra-bold    :   900;

// Descriptive Base Colors

$white              :   #ffffff;
$off-white          :   #fefefe;
$black              :   #0b0b0b;
$grey               :   #6f6f6f;

$mango-tango        :   #EC7403;
$botticelli         :   #cfdce7;
$cornflower         :   #89b6e2;
$danube             :   #6699cc;
$madison            :   #0E2B69;
$gull-gray          :   #9ca4b6;
$atlantis           :   #91c73d;
$punch              :   #dc3135;




// Color Usage

$color-primary      :   $cornflower;
$color-secondary    :   $danube;
$color-deep         :   $madison;
$color-accent       :   $mango-tango;
$color-steel        :   $botticelli;
$color-shadow       :   rgba($black, .2);
$color-gull         :   $gull-gray;

// Color Pallett modifiers

$palettes: (
    orange: (
        xx-light    : lighten($color-accent, 24%),
        x-light     : lighten($color-accent, 16%),
        light       : lighten($color-accent, 8%),
        base        : $color-accent,
        dark        : darken($color-accent, 8%),
        x-dark      : darken($color-accent, 16%)
        ),
    red: (
        xx-light    : lighten($punch , 24%),
        x-light     : lighten($punch , 16%),
        light       : lighten($punch , 8%),
        base        : $punch ,
        dark        : darken($punch , 8%),
        x-dark      : darken($punch , 16%)
        ),
    lt-blue: (
        xx-light    : lighten($color-primary, 24%),
        x-light     : lighten($color-primary, 16%),
        light       : lighten($color-primary, 8%),
        base        : $color-primary,
        dark        : darken($color-primary, 8%),
        x-dark      : darken($color-primary, 16%)
        ),
    dk-blue: (
        xx-light    : lighten($color-secondary, 24%),
        x-light     : lighten($color-secondary, 16%),
        light       : lighten($color-secondary, 8%),
        base        : $color-secondary,
        dark        : darken($color-secondary, 8%),
        x-dark      : darken($color-secondary, 16%)
        ),
    deep-blue: (
        xx-light    : lighten($color-deep, 24%),
        x-light     : lighten($color-deep, 16%),
        light       : lighten($color-deep, 8%),
        base        : $color-deep,
        dark        : darken($color-deep, 8%),
        x-dark      : darken($color-deep, 16%)
        ),
   steel: (
        xx-light    : lighten($color-steel, 24%),
        x-light     : lighten($color-steel, 16%),
        light       : lighten($color-steel, 8%),
        base        : $color-steel,
        dark        : darken($color-steel, 8%),
        x-dark      : darken($color-steel, 16%)
        ),
   gull: (
        xx-light    : lighten($color-gull, 24%),
        x-light     : lighten($color-gull, 16%),
        light       : lighten($color-gull, 8%),
        base        : $color-gull,
        dark        : darken($color-gull, 8%),
        x-dark      : darken($color-gull, 16%)
        ),
    grey: (
        xx-light    : lighten($grey, 47%),
        x-light     : lighten($grey, 35%),
        light       : lighten($grey, 12%),
        base        : $grey,
        dark        : darken($grey, 8%),
        x-dark      : darken($grey, 16%)
        ),
    green: (
        xx-light    : lighten($atlantis, 47%),
        x-light     : lighten($atlantis, 35%),
        light       : lighten($atlantis, 12%),
        base        : $atlantis,
        dark        : darken($atlantis, 8%),
        x-dark      : darken($atlantis, 16%)
        ),
    blue-grey: (
        xx-light    : lighten($color-gull, 47%),
        x-light     : lighten($color-gull, 35%),
        light       : lighten($color-gull, 12%),
        base        : $color-gull,
        dark        : darken($color-gull, 8%),
        x-dark      : darken($color-gull, 16%)
        ),
    white: (
        base        : $off-white,
        dark        : darken($off-white, 4%),
        x-dark      : darken($off-white, 8%),
        xx-dark     : darken($off-white, 12%),
        xxx-dark    : darken($off-white, 16%)

        ),
    black: (
        light       : lighten($black, 10%),
        base        : $black,
        dark        : darken($black, 10%)
        )
    );

//UI Colors

/*$ui-colors  : (
    default :    $fountain-blue,
    sucess  :    $downy ,
    error   :    $valencia,
    warning :    $sulu ,
    info    :    $pastel-green
);*/

//Button Colours 

// Buttons
$btn-colors: ( 
    orange: ( 
        light-col: $color-accent, //background
        dark-col: #b45a06, //border
        font-col: #ffffff //text color
    ),
    lt-blue: ( 
        light-col: $color-primary, //background
        dark-col: #6699cc, //border
        font-col: #ffffff //text color
    ),
    dk-blue: ( 
        light-col: $color-secondary , //background
        dark-col: #4e79a4, //border
        font-col: #ffffff //text color
    ),
    deep-blue: ( 
        light-col: $color-deep , //background
        dark-col: #091c45, //border
        font-col: #ffffff //text color
    ),    
    grey-blue: ( 
        light-col: $color-gull , //background
        dark-col: #737b8d, //border
        font-col: #ffffff //text color
    ),
    white: ( 
        light-col: $white, //background
        dark-col: #cccccc, //border
        font-col: $color-deep //text color
    ),

);

// Text

$base__font-size    :   16px;
$base__line         :   24px; 

// Path to assets

$path--rel          : "../img";